import React from 'react';
import './stylesheets/IndivudalStatBox.css';

const IndivudalStatBox = (props) => {
    const { statNum, statLabel } = props;

    return (
        <div className='stat-box'>
            <p className='stat-number'>{statNum}</p>
            <p className='stat-label'>{statLabel}</p>
        </div>
    )
}


export default IndivudalStatBox;