import React, { useState, useEffect, useRef } from 'react';
import './stylesheets/InstructionModal.css';
import { START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';
import { getTodaysBoardNumber } from '../../services/api';
import Button from '../helpers/button';
import LoadingScreen from '../LoadingScreen';
import { hasPlayedBoard } from '../../services/state';
import { getDateOfBoardNum } from '../../helperFunctions';

const BoardSelectorModal = (props) => {
    const { setShowBoardSelector, switchBoard, inHOF } = props;
    const btnRef = useRef(null);
    const [numberOfBoards, setNumOfBoards] = useState(0);
    const currentBoardNum = Number(inHOF ? localStorage.getItem('HOFBoardNumber') : localStorage.getItem('boardNumber'));

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setShowBoardSelector(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    useEffect(() => {
        const returnNumOfBoards = async () => {
            const numOfBoards = await getTodaysBoardNumber(inHOF)
            setNumOfBoards(numOfBoards)
        }
        returnNumOfBoards()
        // eslint-disable-next-line
    }, [])

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content board-selector-container' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    Select Your Board!

                    {numberOfBoards > 0
                        ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {Array(numberOfBoards).fill(true).map((_, indx) => (
                                <Button
                                    key={indx}
                                    className='yesterday-button'
                                    title={`${inHOF ? 'HOF' : ''} #${indx + 1} ${getDateOfBoardNum(numberOfBoards - indx - 1)}`}
                                    func={() => { switchBoard(indx + 1) }} disabled={currentBoardNum === indx + 1}
                                    style={{ 'marginTop': '1vh', padding: '1vh', borderRadius: '10px', width: '100%' }}
                                    showStatus={true}
                                    statusValue={hasPlayedBoard((indx + 1).toString(), inHOF)}
                                />
                            ))
                            }
                        </div>
                        :
                        <LoadingScreen smallLoading={true} />
                    }
                </h2>
            </div>
        </div>
    )
}


export default BoardSelectorModal;