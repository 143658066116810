import React, { useState, useEffect } from 'react';
import './stylesheets/ShowAnalytics.css'
import { getGivenBoardStatistics } from '../../services/api';
import { convertMillisToSeconds, getCurrentLeague } from '../../helperFunctions';
import { CATEGORY_COLOR_DATA_MAP, LEAGUE_GOAT_MAP } from '../../siteConstants';
import LoadingScreen from '../LoadingScreen';

const current_league = getCurrentLeague();

const ShowBasicAnalytics = (props) => {
    const { boardNumber, setShowAnalyticsPanel, inModal, boardStatsGlobal, setBoardStatsGlobal } = props;

    const [winRate, setWinRate] = useState(0)
    const [avgTime, setAvgTime] = useState(0)
    const [otherStats, setOtherStats] = useState({})

    useEffect(() => {
        const getStats = async () => {
            const response = await getGivenBoardStatistics(boardNumber)
            setWinRate(response['WinRate'])
            setAvgTime(response['AvgTime'])
            const restOfStats = {
                PctLivesOne: response['PctLivesOne'],
                PctLivesTwo: response['PctLivesTwo'],
                PctLivesThree: response['PctLivesThree'],
                PctLivesAll: response['PctLivesAll'],
                PctSolvedYellowFirst: response['PctSolvedYellowFirst'],
                PctSolvedOrangeFirst: response['PctSolvedOrangeFirst'],
                PctSolvedRedFirst: response['PctSolvedRedFirst'],
                PctSolvedBlackFirst: response['PctSolvedBlackFirst'],
            }
            setOtherStats(otherStats => ({
                ...otherStats,
                ...restOfStats
            }))
            setBoardStatsGlobal(
                {
                    WinRate: response['WinRate'],
                    AvgTime: response['AvgTime'],
                    RestOfStats: restOfStats
                })
        }
        try {
            if (Object.keys(boardStatsGlobal).length === 0) {
                getStats()
                return
            }
            setWinRate(boardStatsGlobal['WinRate'])
            setAvgTime(boardStatsGlobal['AvgTime'])
            setOtherStats(boardStatsGlobal['RestOfStats'])

        }
        catch { }
        // eslint-disable-next-line
    }, [boardNumber])

    const renderOtherStats = () => {
        const pctLives = {
            'PctLivesOne': 'One Life',
            'PctLivesTwo': 'Two Lives',
            'PctLivesThree': 'Three Lives',
            'PctLivesAll': 'All Lives',
        }
        const pctSolves = {
            'PctSolvedYellowFirst': 'Easy',
            'PctSolvedOrangeFirst': 'Medium',
            'PctSolvedRedFirst': 'Hard',
            'PctSolvedBlackFirst': 'All Star',
        }

        return (
            <div className='advanced-container'>
                <div className='line-divider' />
                <div>
                    <p className='section-label'>Category solved first... </p>
                    <div className='items-container'>
                        <div className='items-sub-container'>
                            {Object.keys(pctSolves).map((item, i) => (
                                <div key={i} className='time-taken-container'>
                                    <p className='item-key'> {pctSolves[item]} :</p>
                                </div>

                            ))}
                        </div>
                        <div className='items-sub-container'>
                            {Object.keys(pctSolves).map((item, i) => (
                                <div key={i} className='time-taken-container'>
                                    <p className='item-key' style={{ color: i === 3 ? 'silver' : CATEGORY_COLOR_DATA_MAP[current_league][i] }}> {otherStats[item]}%</p>
                                </div>

                            ))}
                        </div>
                    </div>

                </div>
                <div className='line-divider' />
                <div>
                    <p className='section-label'>Won with... </p>
                    <div className='items-container'>
                        <div className='items-sub-container'>
                            {Object.keys(pctLives).map((item, i) => (
                                <div key={i} className='time-taken-container'>
                                    <p className='item-key'> {pctLives[item]} :</p>
                                </div>

                            ))}
                        </div>
                        <div className='items-sub-container'>
                            {Object.keys(pctLives).map((item, i) => (
                                <div key={i} className='time-taken-container'>
                                    <p className='item-key' style={{ color: i === 3 ? 'silver' : CATEGORY_COLOR_DATA_MAP[current_league][i] }}> {otherStats[item]}%</p>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    const determineColorRate = (rate) => {
        if (rate > 40) {
            return inModal ? 'lightgreen' : 'green'
        }
        if (rate > 20) {
            return inModal ? '#ffff33' : CATEGORY_COLOR_DATA_MAP[current_league][0]
        }
        return '#ff3333'
    }

    const determineColorTimeTaken = (timeTaken) => {
        if (timeTaken < 300) {
            return inModal ? 'lightgreen' : 'green'
        }
        if (timeTaken < 400) {
            return inModal ? '#ffff33' : CATEGORY_COLOR_DATA_MAP[current_league][0]
        }
        return '#ff3333'
    }

    return (
        <>
            {
                avgTime > 0 && winRate > 0 ?
                    <div className='basic-analytics-container' style={{ marginTop: !inModal && '0.5em' }}>
                        <div className='time-taken-container'>
                            <h2 className='basic-analytics-text' style={{ color: inModal && 'white' }}>
                                Win Rate:
                            </h2>
                            <h2 className='basic-analytics-text' style={{ color: determineColorRate(winRate) }}>
                                {winRate}%
                            </h2>
                        </div>
                        <div className='time-taken-container'>
                            <h2 className='basic-analytics-text' style={{ color: inModal && 'white' }}>
                                Average Time Taken:
                            </h2>
                            <h2 className='basic-analytics-text basic-analytics-green' style={{ color: determineColorTimeTaken(Number(convertMillisToSeconds(avgTime))) }}>
                                {convertMillisToSeconds(avgTime)}s
                            </h2>
                        </div>

                        {
                            !inModal ? <button className='stats-button' onClick={() => setShowAnalyticsPanel(true)}>
                                <p className='stats-button-text'>Show More Stats</p>
                            </button>
                                :
                                <div style={{ width: '100%' }}>
                                    {renderOtherStats()}
                                </div>
                        }
                    </div>
                    :
                    inModal && <LoadingScreen text={`Texting ${LEAGUE_GOAT_MAP[current_league]} for Stats...`} textStyle={{ color: 'white' }} smallLoading={true} />
            }
        </>

    )
}

export default ShowBasicAnalytics;