
const initialState = (boardNumber) => {
    const newState = getCurrentGameData();
    newState[boardNumber] = {};
    return newState;
};

const inHOFGameDataString = () => {
    const inHOF = localStorage.getItem('inHOF') === 'true'
    return inHOF ? 'gameDataHOF' :'gameData'
}

// Return true if boardNumber is in GameData
export const checkGivenBoardData = (boardNumber) => {
    return localStorage.getItem(inHOFGameDataString()) !== null && boardNumber in getCurrentGameData()
}

export const getCurrentGameData = () => {
    return localStorage.getItem(inHOFGameDataString()) !== null ? JSON.parse(localStorage.getItem(inHOFGameDataString())) : {};
}

export const initializeCurrentBoardNumber = (boardNumber) => {
    if (localStorage.getItem(inHOFGameDataString()) === null || !(boardNumber in getCurrentGameData())) localStorage.setItem(inHOFGameDataString(),JSON.stringify(initialState(boardNumber)));
}

export const setCurrentGameData = (boardNumber, propertyName, propertyValue) => {
    const game_data = getCurrentGameData();
    // console.log(game_data)
    game_data[boardNumber][propertyName] = propertyValue

    localStorage.setItem(inHOFGameDataString(), JSON.stringify(game_data));
}

export const getGameDataProperty = (boardNumber, propertyName) => {
    const game_data = getCurrentGameData();

    return boardNumber in game_data && propertyName in game_data[boardNumber] ? game_data[boardNumber][propertyName] : null
}

export const removeGivenBoardData = (boardNumber) => {
    const game_data = getCurrentGameData();

    if (game_data[boardNumber] === null) return 
    delete game_data[boardNumber]
    localStorage.setItem(inHOFGameDataString(), JSON.stringify(game_data));
} 

export const removeGivenBoardDataProperty = (boardNumber, propertyName) => {
    const game_data = getCurrentGameData();

    if (game_data[boardNumber][propertyName] === null) return 
    delete game_data[boardNumber][propertyName]
    localStorage.setItem(inHOFGameDataString(), JSON.stringify(game_data));
} 

export const hasPlayedWeeksHOF = () => {
    // Write a function that returns true if the user has played the most recent HOF Board
    if (localStorage.getItem('gameDataHOF') == null) {
        return false;
    }
    const game_data = JSON.parse(localStorage.getItem('gameDataHOF'));
    const weeksHOF = localStorage.getItem('weeksHOFBoardNumber') ?? 0;

    return weeksHOF in game_data;
}

export const hasPlayedBoard = (idx, inHOF) => {
    const game_data = JSON.parse(localStorage.getItem(inHOF ? 'gameDataHOF' : 'gameData'))
    const game_data_keys = Object.keys(game_data)

    return game_data_keys.includes(idx) && game_data[idx] && game_data[idx]['timeTaken']
}