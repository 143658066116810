import React from 'react';
import './stylesheets/SmallAnnoucementModal.css';
import { MAIN_BACKGROUND_COLOR_DARKER_MAP } from '../../siteConstants';
// import X_ICON from '../../assets/x.png'
import { getCurrentLeague } from '../../helperFunctions';
// import { useNavigate } from "react-router-dom";

const current_league = getCurrentLeague();

const SmallAnnoucementModal = (props) => {
    // const { setSmallAnnoucementModal } = props;
    // const navigate = useNavigate();

    return (
        <div className='main-feature-container' style={{ backgroundColor: MAIN_BACKGROUND_COLOR_DARKER_MAP[current_league] }}>
            <div className='close-icon' />
        </div>
    )
}


export default SmallAnnoucementModal;