import React, { useEffect, useRef } from 'react';
import './board_stylesheets/HintsModal.css';
import { START_MODAL_BACKGROUND_COLOR, DIFFICULTY_COLOR_DATA, DARK_COLORS_WHITE_TEXT, CATEGORY_COLOR_DATA_MAP } from '../../siteConstants';
import Button from '../helpers/button';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const HintsModal = (props) => {
    const { setShowHintsModal, category_data, revealedHints, setRevealedHints } = props;
    const btnRef = useRef(null)

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setShowHintsModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    const revealGivenHint = (i) => {
        const new_hints = [...revealedHints]
        new_hints[i] = true
        setRevealedHints(new_hints)
    }

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR, alignItems: 'center' }}>
                <h2 className='modal-title' style={{ fontSize: '3vh', margin: '0', marginBlockStart: '0' }}>
                    Hints
                </h2>

                <p className='similarity-label'>Click to reveal a category</p>

                {category_data.map((category, i) => (
                    revealedHints[i] ?
                        <p
                            key={i}
                            className='revealed_hint'
                            style={{ color: DARK_COLORS_WHITE_TEXT.includes(CATEGORY_COLOR_DATA_MAP[current_league][i]) ? 'white' : 'black', backgroundColor: CATEGORY_COLOR_DATA_MAP[current_league][i], width: '16em' }}>
                            {category?.toUpperCase()}
                        </p>
                        :
                        <Button
                            key={i}
                            style={{ margin: 0, marginTop: '2vh', backgroundColor: CATEGORY_COLOR_DATA_MAP[current_league][i], border: 'none', width: '16em' }}
                            textStyle={{ color: i === 3 ? 'gold' : 'white' }}
                            title={`Reveal ${DIFFICULTY_COLOR_DATA[i]} Hint`}
                            func={() => revealGivenHint(i)}
                        />

                ))}


                {/* <Button style={{ margin: 0, marginTop: '0.5vh' }} title={'See Your Results'} func={revealNextHint} /> */}
            </div>
        </div>
    )
}


export default HintsModal;