import React from 'react';
import './helpers_stylesheets/button.css';

const Button = (props) => {
    const { title, func, disabled, style, textStyle, showStatus = false, statusValue } = props;

    return (
        <button className='button' style={style} onClick={func} disabled={disabled}>
            {
                showStatus ?
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingInline: '1.5em' }}>
                        <p className='buttonText' style={{ color: disabled ? 'grey' : 'black', ...textStyle }}>
                            {title}
                        </p>
                        <p className='buttonText' style={{ margin: 0 }}>
                            {statusValue ? '✅' : '❓'}
                        </p>
                    </div>
                    :
                    <p className='buttonText' style={{ color: disabled ? 'grey' : 'black', ...textStyle }}>
                        {title}
                    </p>
            }
        </button>
    )
}


export default Button;