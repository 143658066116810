import React from 'react';
import './stylesheets/loadingScreen.css'
import { getCurrentLeague } from '../helperFunctions';
import { CURRENT_LEAGUE_ICON_SPINNER_MAP } from '../siteConstants';

const current_league = getCurrentLeague();

const current_league_spinner = CURRENT_LEAGUE_ICON_SPINNER_MAP[current_league];

const Spinner = () => {
    const speed = 2
    return (
        <img style={{ animation: `spin ${speed}s linear infinite`, width: '10vh' }} src={current_league_spinner} alt="img" />
    )
}

const LoadingScreen = (props) => {
    const { text, textStyle, inHOF, smallLoading } = props;

    // const navigate = useNavigate();

    return (
        <div className='loading-screen-container'
            style={{ height: smallLoading ? '20vh' : '100vh' }}
        >
            <Spinner />

            <p style={{ color: inHOF ? 'white' : 'black', ...textStyle }} >{text}</p>
            {/* <Button style={{ width: '15em', margin: 0, marginTop: '.5vh', backgroundColor: '#2b59ff', border: 'none' }}
                textStyle={{ color: 'white' }}
                title={'Play HOF'}
                func={() => { navigate("/hof"); window.location.reload() }} /> */}
        </div>
    )
}
export default LoadingScreen;