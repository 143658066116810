import React, { useEffect, useRef } from 'react';
import './stylesheets/InstructionModal.css';
import { START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';

const BigAnnouncementModal = (props) => {
    const { setStartModal } = props;
    const btnRef = useRef(null);

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setStartModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    <b style={{ color: 'crimson' }}>DOTA Connections</b> is here!
                </h2>
            </div>
        </div>
    )
}


export default BigAnnouncementModal;
