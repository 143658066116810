import React, { useEffect, useRef } from 'react';
import './stylesheets/AnalyticsModal.css';
import { START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';
import ShowBasicAnalytics from './ShowAnalytics';
import Button from '../helpers/button';
import { getGameDataProperty } from '../../services/state';
import { getConsecutivePlayStreak, getConsecutiveWinStreak, getWinPercentageAndTotalGamesPlayed } from '../../helperFunctions';
import IndivudalStatBox from './IndividualStatBox';

const AnalyticsModal = (props) => {
    const { boardNumber, setViewingResults, setShowAnalyticsPanel, boardStatsGlobal, setBoardStatsGlobal, switchBoard } = props;
    const btnRef = useRef(null);
    const todaysBoardNumber = Number(localStorage.getItem('todaysBoardNumber'))
    const consecutiveStreakNum = getConsecutivePlayStreak()
    const consecutiveWinStreakNum = getConsecutiveWinStreak()
    const { gamesPlayed, gamesWonPercentage } = getWinPercentageAndTotalGamesPlayed()

    const statsList = [
        {
            label: 'Current\nStreak',
            number: consecutiveStreakNum
        },
        {
            label: 'Win\nStreak',
            number: consecutiveWinStreakNum
        },
        {
            label: 'Total Games\nPlayed',
            number: gamesPlayed
        },
        {
            label: 'Win %',
            number: gamesWonPercentage
        },

    ]

    useEffect(() => {

        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setShowAnalyticsPanel(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    const showResultsModal = () => {
        setShowAnalyticsPanel(false); setViewingResults(true);
    }

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR, alignItems: 'center' }}>

                <h2 className='modal-sub-title'>
                    Your Stats
                </h2>
                <div className='individual-stat-container'>
                    {statsList.map((stat, i) => (
                        <IndivudalStatBox key={i} statNum={stat.number} statLabel={stat.label} />
                    ))}
                </div>

                <h2 className='modal-sub-title'>
                    Analytics for Board #{boardNumber}
                </h2>

                {
                    Number(localStorage.getItem('todaysBoardNumber')) === boardNumber || boardNumber <= 1 ?
                        <div className='no-stats-container'>
                            {
                                boardNumber <= 1 ?
                                    <p className='similarity-label-yesterday'>No Stats Available Yet</p>
                                    :
                                    <button className='yesterday-button' onClick={() => { switchBoard(todaysBoardNumber - 1) }}>
                                        <p className='similarity-label-yesterday'>View Yesterday's Board Analytics</p>
                                    </button>
                            }

                        </div>
                        :
                        <ShowBasicAnalytics boardNumber={boardNumber} setShowAnalyticsPanel={setShowAnalyticsPanel} inModal={true} boardStatsGlobal={boardStatsGlobal} setBoardStatsGlobal={setBoardStatsGlobal} />
                }
                {getGameDataProperty(boardNumber, 'gameResult') !== null && <Button style={{ margin: 0, marginTop: '0.5vh' }} title={'See Your Results'} func={showResultsModal} />}

            </div>
        </div>
    )
}


export default AnalyticsModal;