import React, { useEffect, useRef } from 'react';
import './stylesheets/InstructionModal.css';
import { CURRENT_LEAGUE_MAP, CURRENT_LEAGUE_NAME_MAP, GREY_BUTTON_BACKGROUND_COLOR, START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';
import ColorDifficulty from '../helpers/ColorDifficulty';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const InstructionModalHOF = (props) => {
    const { setStartModal } = props;
    const btnRef = useRef(null);

    const current_league_name = CURRENT_LEAGUE_MAP[current_league];
    const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setStartModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    {league_title} Hall Of Fame
                </h2>
                <h4> It's {league_title}, but 5x harder</h4>
                <br></br>
                <h4><b>Goal:</b> Find groups of five {current_league_name} players that share a similarity.</h4>

                <h5>Each group is associated with a color that reveals its difficulty.</h5>

                <ColorDifficulty />
                <h5>Each board is designed to trick you. Guess with caution!</h5>
                <h5>No more mistake limit! Still stuck? Use the Give Up button to reveal answers</h5>
                <h5>Board refreshes weekly on Thursdays 8PM EST</h5>

                <h5>Missed a week? No problem. You can play previous boards at ANY time </h5>


                <div className='get-started-container'>
                    <h4 style={{ fontSize: '1.2em' }}>Good Luck!</h4>
                    <button className='started-button' style={{ backgroundColor: GREY_BUTTON_BACKGROUND_COLOR }} onClick={() => setStartModal(false)} >
                        <p className='started-button-label'>
                            Get Started
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default InstructionModalHOF;