import axios from 'axios';
import * as qs from 'qs';
import { decrypt,
  // encryptString,
 } from '../helperFunctions';

// const frontend_pass = process.env.REACT_APP_TOKEN_PASSWORD;
const frontend_key = process.env.REACT_APP_TOKEN_KEY;
const current_league = process.env.REACT_APP_CURRENT_LEAGUE_ID ?? 'nba';

const BASE_URL = 'https://dota-connections-ceawf3hcafazb4ba.eastus2-01.azurewebsites.net'
const URL_SUB_PATH = `/api/${current_league}`

const API_URL = BASE_URL + URL_SUB_PATH
const getToken = async () => {
  //  const cryptoInfo = CryptoJS.AES.encrypt(JSON.stringify(str), "secret");
  // const encrypted_password = await encryptString(frontend_pass.toString(), "b14ea5298a4e4153bbce2ea2315a1976")
  // const encrypted_password = "n7Fbe75pXtYK4arNavroH6EOLgu6gbgYmb71B0WBPQE="
  const encrypted_password = 'n7Fbe75pXtYK4arNavroH6EOLgu6gbgYmb71B0WBPQE=';

  // console.log ("*******")
  // console.log (frontend_key.toString())
  // console.log (encrypted_password)
  // console.log (frontend_pass.toString())
  // console.log  ("*******")
  // return
  
  try {
    const body = {
      'Username' : frontend_key.toString(),
      'Password' : encrypted_password
    }
    const stringifiedEvent = qs.stringify(body);
    // console.log(stringifiedEvent)
    const response = await axios({
      baseURL: BASE_URL,
      method: 'post',
      url: '/GetToken',
      data: stringifiedEvent,
    });
    const decrypted_token = await decrypt(response.data, "b14ea5298a4e4153bbce2ea2315a1976")
    // console.log(response.data)
    // console.log(decrypted_token)
    // console.log("******")
    return decrypted_token;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllDotaHeroes = async () => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetAllHeroes',
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDotaHero = async (id) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetHeroByName',
      params: {id}
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getBoardAndGroup = async () => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetBoardOfTheDay',

    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const get_ip_address = async () => {
  try {
    const response = await axios.get("https://api.ipify.org/?format=json");

    return response.data.ip;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const finalizeBoardAnswers = async (body) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'post',
      url: '/FinalizeBoardAnswers',
      data: body,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const finalizeHOFBoardAnswers = async (body) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'post',
      url: '/FinalizeHOFBoardAnswers',
      data: body,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getBoardById = async (id) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetBoardById',
      params: {id}
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBoardByNumber = async (inHOF, number) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: inHOF ? '/GetHOFBoardByNumber' : '/GetBoardByNumber',
      params: {number}
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const checkUserPasscode = async (passcode) => {
  try {
    const response = await axios.post(BASE_URL+ `/AuthBetaUser?accessCode=${passcode}`)
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBoardOfTheDayUpdatedAt = async (boardNumber) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetUpdatedAt',
      params: {boardNumber}
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getTodaysBoardNumber = async (inHOF=false) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: inHOF ? '/GetHOFBoardNumber' : '/GetBoardNumber'
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return 1;
    // throw error;
  }
};

export const getGivenBoardStatistics = async (boardNumber) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetBoardStatistics',
      params: {boardNumber}
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getHOFBoardOfTheWeek = async (boardNumber) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: '/GetHOFBoardOfTheWeek',
      params: {boardNumber}
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNumberOfBoards = async (inHOF=false) => {
  try {
    const front_end_token = await getToken()
    const response = await axios({
      headers: { Authorization: `Bearer ${front_end_token}` },
      baseURL: API_URL,
      method: 'get',
      url: inHOF ? '/GetNumberOfHOFBoards' : '/GetNumberOfBoards'
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
