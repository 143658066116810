import React from 'react';
import './helpers_stylesheets/ColorDifficulty.css';
import { CATEGORY_COLOR_DATA_MAP, DIFFICULTY_COLOR_DATA } from '../../siteConstants';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const ColorDifficulty = () => {
    const league_category_color_set = CATEGORY_COLOR_DATA_MAP[current_league];

    const renderColorDifficulty = () => {
        const inHOF = localStorage.getItem('inHOF') === 'true'
        const rowsToRender = inHOF ? 5 : 4;
        const rows = []
        for (let i = 0; i < rowsToRender; i++) {
            rows.push(
                <div key={i} className='difficulty-container'>
                    <div className='colorSquare' style={{ backgroundColor: league_category_color_set[i] }} />
                    <p className='difficulty-label'>= {DIFFICULTY_COLOR_DATA[i]}</p>
                </div>
            )
        }
        return rows
    }

    return (
        <div className='difficulty-container-main'>
            <div className='difficulty-container-sub'>
                {renderColorDifficulty()}
            </div>
        </div>
    )
}

export default ColorDifficulty;