import React from 'react';
import { privacy_policy } from '../../constant_data/privacyPolicy';
import './stylesheets/PrivacyPolicy.css'
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    return (
        <div className='main-privacy'>

            <h2 onClick={() => { navigate("/") }} className='privacy-title'>Back to Normal</h2>

            <div className='main-privacy-sub'>
                <h2>Our Privacy Policy</h2>
                <div>
                    {privacy_policy.map((section, i) => (
                        <div key={i}>
                            <h3 className='privacy-subtitle'>{section.label}</h3>
                            {section.descs.map((descs, j) => (
                                <p key={`${i} + ${j} `}>{descs}</p>
                            ))
                            }
                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
}


export default PrivacyPolicy;