import React from 'react';
import './stylesheets/WarningModal.css';
import { WARNING_STRINGS_RED, WARNING_STRINGS_GREEN, WARNING_COLOR_GREEN, WARNING_COLOR_RED } from '../../siteConstants'

const WarningModal = (props) => {
    const { warningStringIndex, is_red_warning } = props;

    return (
        <div className='warning-modal-content' style={{ backgroundColor: is_red_warning ? WARNING_COLOR_RED : WARNING_COLOR_GREEN, marginBottom: '1vh' }}>
            <h4 className='warning' style={{ marginTop: '0', color: 'white', justifyContent: 'center' }}>
                {is_red_warning ? WARNING_STRINGS_RED[warningStringIndex] : WARNING_STRINGS_GREEN[warningStringIndex]}
            </h4>
        </div>
    )
}

export default WarningModal;