import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom"
import App from "./App"
import { getBasicData } from './userHelperFunctions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
// import { getCurrentLeague } from './helperFunctions';

// const current_league = getCurrentLeague();
function RoutesMain() {

    const [basicData, setBasicData] = useState({});
    useEffect(() => {
        async function fetchBasicData() {
            const response = await getBasicData()
            setBasicData(response)
        }
        fetchBasicData();
    }, [])


    return (
        <div className="App">
            {Object.keys(basicData).length !== 0 &&
                <Routes>
                    <Route path="/" element={<App basicData={basicData} inHOF={false} />} />
                    <Route path="/hall-of-fame" element={<App basicData={basicData} inHOF={true} />} />
                    <Route path="/hof" element={<App basicData={basicData} inHOF={true} />} />
                    <Route path="/*" element={<App basicData={basicData} inHOF={false} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            }
        </div>
    )
}

export default RoutesMain