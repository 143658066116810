import React, { useEffect, useRef } from 'react';
import './stylesheets/InstructionModal.css';
import { MODAL_CONST_DATA } from '../../constant_data/startModalPlayerPictures';
import { CURRENT_LEAGUE_NAME_MAP, GREY_BUTTON_BACKGROUND_COLOR, START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';
import ColorDifficulty from '../helpers/ColorDifficulty';
import { getCurrentLeague } from '../../helperFunctions';

const current_league = getCurrentLeague();

const InstructionModal = (props) => {
    const { setStartModal } = props;
    const btnRef = useRef(null);

    // const current_league_name = CURRENT_LEAGUE_MAP[current_league];
    const league_title = CURRENT_LEAGUE_NAME_MAP[current_league];

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setStartModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    const renderHistory = () => {
        const rows = []

        const category_data = MODAL_CONST_DATA[current_league].category_data;
        const player_data = MODAL_CONST_DATA[current_league].player_data;

        for (let i = 0; i < 2; i++) {
            rows.push(
                <div key={i} className='example-div'>
                    <p className='similarity-label'>{category_data[i].toUpperCase()}</p>
                    <div className='player-row'>
                        {player_data.map((player, j) => (
                            player['GroupId'] - 1 === i &&
                            <img
                                key={j}
                                draggable={false}
                                className='player-icon'
                                src={player['ImageSource']}
                                alt="player"
                            />
                        ))
                        }
                    </div>
                </div>
            )
        }
        return rows
    }

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    How to play {league_title}
                </h2>
                <h4> We're like the NYT Connections, but for Dota!</h4>
                <h4><b>Your Goal:</b> Find groups of four dota heroes that share a similarity.</h4>
                <h4>These can be based on in-game attributes, lore, pro games, and pretty much anything else.</h4>
                <h4 className='example-label'>Example:</h4>
                {renderHistory()}
                <h5>Select the four heroes and tap Submit to check if your guess is correct.</h5>
                <h5>You have four chances to get the right groups.</h5>

                <h5>Each group is associated with a color that reveals its difficulty.</h5>

                <ColorDifficulty />

                <div className='get-started-container'>
                    <h4 style={{ fontSize: '1.2em' }}>Good Luck!</h4>
                    <button className='started-button' style={{ backgroundColor: GREY_BUTTON_BACKGROUND_COLOR }} onClick={() => setStartModal(false)} >
                        <p className='started-button-label'>
                            Get Started
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default InstructionModal;