
import { get_ip_address } from './services/api';
import { generateNewUserId, getBrowser } from './helperFunctions';


export const getBasicData = async () => {
    let userId;
    let isNewUser = true

    if (localStorage.getItem('userId')) {
        userId = localStorage.getItem('userId')
        isNewUser = false
    } else {
        userId = generateNewUserId()
    }
    
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'N/A';
    const userIpAddress = await get_ip_address()
    const userAgent = navigator.userAgent ?? 'N/A';
    const userBrowser = getBrowser(userAgent)

    return { userId, userTimezone, userIpAddress, userAgent, userBrowser, isNewUser }
}