import React, { useEffect, useRef } from 'react';
import './stylesheets/EndModal.css';
import { START_MODAL_BACKGROUND_COLOR } from '../../siteConstants';
import Button from '../helpers/button';

const GiveUpModal = (props) => {
    const { setShowGiveUpModal, giveUpFunc, showHintFunc } = props;
    const btnRef = useRef(null);

    useEffect(() => {
        const clickedOutside = (e) => {
            if (e.srcElement.className === 'start-modal') setShowGiveUpModal(false)
        }
        document.body.addEventListener('click', clickedOutside)
        return () => document.body.removeEventListener('click', clickedOutside)
    })

    return (
        <div ref={btnRef} className='start-modal'>
            <div className='modal-content' style={{ backgroundColor: START_MODAL_BACKGROUND_COLOR }}>
                <h2 className='modal-title'>
                    You Sure You Want To Give Up?
                </h2>


                <div className='give-up-button-modal' >
                    <Button style={{ margin: 0, marginTop: '0.5vh' }} title={'Use Hints'} func={() => { setShowGiveUpModal(false); showHintFunc(true); }} />
                    <Button style={{ margin: 0, marginTop: '0.5vh' }} title={'Give Up'} func={() => { setShowGiveUpModal(false); giveUpFunc(); }} />

                </div>

            </div >
        </div >

    )
}


export default GiveUpModal;