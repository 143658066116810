export const MODAL_CONST_DATA = {

    nba: {
        category_data: [
            "7'3 or taller", "All Stars that have played with Lebron James",
        ],

        player_data: [
            {
                id: 0,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/2397.png',
                name: 'Yao Ming',
                GroupId: 1
            },
            {
                id: 1,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/1629626.png',
                name: 'Bol Bol',
                GroupId: 1
            },
            {
                id: 2,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/1626246.png',
                name: 'Boban Marjanovic',
                GroupId: 1
            },
            {
                id: 3,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/1629605.png',
                name: 'Tacko Fall',
                GroupId: 1
            },
            {
                id: 4,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/202681.png',
                name: 'Kyrie Irving',
                GroupId: 2
            },
            {
                id: 5,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/2547.png',
                name: 'Chris Bosh',
                GroupId: 2
            },
            {
                id: 6,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/2548.png',
                name: 'Dwanye Wade',
                GroupId: 2
            },
            {
                id: 7,
                ImageSource: 'https://cdn.nba.com/headshots/nba/latest/1040x760/201567.png',
                name: 'Kevin Love',
                GroupId: 2
            }
        ]
    },
    nfl: {
        category_data: [
            "Current LA Rams Players", "Last Four MVPs",
        ],

        player_data: [
            {
                id: 0,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4360797.png',
                name: 'Tutu Atwell',
                GroupId: 1
            },
            {
                id: 1,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/12483.png',
                name: 'Matthew Stafford',
                GroupId: 1
            },
            {
                id: 2,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4426515.png',
                name: 'Puka Nacua',
                GroupId: 1
            },
            {
                id: 3,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/4430737.png',
                name: 'Kyren Williams',
                GroupId: 1
            },
            {
                id: 4,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/2576414.png&w=350&h=254',
                name: 'Joe Flacco',
                GroupId: 2
            },
            {
                id: 5,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/15864.png',
                name: 'Geno Smith',
                GroupId: 2
            },
            {
                id: 6,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/3915511.png',
                name: 'Joe Burrow',
                GroupId: 2
            },
            {
                id: 7,
                ImageSource: 'https://a.espncdn.com/combiner/i?img=/i/headshots/nfl/players/full/14876.png',
                name: 'Ryan Tannehill',
                GroupId: 2
            }
        ]
    },
    dota: {
        category_data: [
            "Heroes with Bonus Night Vision", "Heroes with Arcanas",
        ],

        player_data: [
            {
                id: 0,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/sniper.png',
                name: 'Sniper',
                GroupId: 1
            },
            {
                id: 1,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/bane.png',
                name: 'Bane',
                GroupId: 1
            },
            {
                id: 2,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/slark.png',
                name: 'Slark',
                GroupId: 1
            },
            {
                id: 3,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/night-stalker.png',
                name: 'Nightstalker',
                GroupId: 1
            },
            // // // //
            {
                id: 4,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/shadow-fiend.png',
                name: 'Shadow Fiend',
                GroupId: 2
            },
            {
                id: 5,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/techies.png',
                name: 'Techies',
                GroupId: 2
            },
            {
                id: 6,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/queen-of-pain.png',
                name: 'Queen of Pain',
                GroupId: 2
            },
            {
                id: 7,
                ImageSource: 'https://www.dotafire.com/images/hero/icon/lina.png',
                name: 'Lina',
                GroupId: 2
            }
        ]
    }
}